import React from "react";

const Main = () => {
  return (
    <div className="w-full pt-[100px]" style={{ height: "calc(100vh - 97px"}}>
      <div className="main-container">
        <h3 className="text-dark text-[50px]  mb-4">About.</h3>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <p className="text-[22px] text-dark">
              Since our creation,{" "}
              <span className="text-primary">Autopilot</span> has been entirely
              focused on building the companies of tomorrow.
            </p>
          </div>
          <div>
            <p className="text-[15px] text-gray">
              Autopilot is a team of talented strategy and architecture
              consultants. Our people have first-hand experience at top tier
              financial services and technology organisations, combined with
              strong mindset for enterpreneurship. We work and consult
              worldwide.
              <br />
              <br />
              In order to preserve highest standards of execution and our
              reputation, we don't deviate from assignments within our core
              expertise of business and technology Enterprise Architecture and
              Change Management.
              <br />
              <br />
              For initiatives extending beyond Enterprise Architecture and
              Change functions we partner with like-minded best in the class
              service providers in the industry. Our emphasis is on quality and
              therefore we remain small, focused, and consequently profitable.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
